/* eslint-disable*/
import React, { useEffect, useRef } from "react";
import BackButton from "../../component/BackButton";
import HelperViewModel from "../../viewmodels/helper";
import { API_URL, BOT_ID } from "../../utils/constants";
import { useParams } from "react-router-dom";


function Index() {
  const { onBackClick } = HelperViewModel();
  const { message } = useParams();
  const intervalRef = useRef();

  useEffect(() => {
    if (message) {
      let id = setInterval(() => {
        const iframe = document.getElementById("chatFrame");
        const nprogress =
          iframe.contentWindow.document.getElementById("nprogress");
        if (!nprogress) {
          setMessage();
        }
      }, 2000);
      intervalRef.current = id;
    }
    return ()=>{
      clearInterval(intervalRef.current);
    }
  }, []);
  const setMessage = () => {
    clearInterval(intervalRef.current);
    if (message) {
      const iframe = document.getElementById("chatFrame");
      new Promise((resolve, reject) => {
        iframe.contentWindow.document.getElementById("messageBtn")?.click();
        resolve();
      })
        .then(() => {
          const inputBox =
            iframe.contentWindow.document.getElementById("textMessage");
            inputBox.value = message;
          return;
        })
        .then(() => {
          iframe.contentWindow.document.getElementById("sendMessage")?.click();
          iframe.contentWindow.document.getElementById("textMessage").value = ''
        });
    }
  };
  return (
    <>
      <div className="d-flex flex-column h-100 w-100">
        <div className="p-3 text-start">
          <BackButton backOnClick={() => onBackClick("/")} />
        </div>
        <iframe
          className="w-100 h-100"
          id="chatFrame"
          src={`${API_URL}/app/${BOT_ID}`}
          title="Bynge Chat"
        ></iframe>
      </div>
    </>
  );
}

export default Index;
