import React from "react";
import { Spinner } from "reactstrap";
// import logo from '../../assets/images/Bynge Logo.png'
import logo from "../../assets/image/logo.png";

const Loader = () => {
  return (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="position-relative mw-100 mx-5 px-5">
        <img src={logo} alt="logo" className="mw-100 mh-100 d-block mb-5" />
      </div>
      <Spinner color="danger" />
      <p
        style={{
          color: " rgb(255, 103, 131)",
          marginTop: "18px",
          textAlign: "center",
          fontWeight: "900",
          letterSpacing: "2px",
          fontSize: "12px",
        }}
      >
        Loading
      </p>
    </div>
  );
};

export default Loader;
