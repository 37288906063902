import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, userDetails }) => {
  return userDetails && userDetails.slug ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
