import React from "react";
import { Navigate } from "react-router-dom";

const UnauthenticatedRoute = ({ children, userDetails }) => { 
  return !userDetails || !userDetails.slug ? (
    children
  ) : (
    <Navigate to="/restaurants" replace />
  );
};

export default UnauthenticatedRoute;
