import { ToastContainer } from "react-toastify";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "../node_modules/bootstrap/dist/js/bootstrap.esm";
import "./App.css";

import "./assets/css/styles.css";
import "./assets/css/icons.css";
import "./assets/sass/base/fonts/stylesheet.css";
import "react-toastify/dist/ReactToastify.css";

import RouterComponent from "./routes";
import AppViewModel from "./viewmodels/App";
import UILoader from "./component/UIloader";

function App() {
  const { userDetails, loading } = AppViewModel();

  if (typeof window !== "undefined") {
    window.scroll(0, 0);
  }

  return (
    <main className="App">
      {loading ? <UILoader /> : <RouterComponent userDetails={userDetails} />}
      <ToastContainer newestOnTop />
    </main>
  );
}

export default App;
