import axios from "axios";
import * as constant from "../constants";

const API_URL = constant.API_URL;

// axios interceptor request to setup token in header
axios.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("userAccessToken");

  config.baseURL = API_URL;
  config.headers = {
    common: {
      Accept: "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  };
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

//axios interceptor response to set auto logout
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.message === "Network Error") {
      error.response = {
        status: 500,
        data: {
          message: "Network Error | Network Unavailable",
        },
      };
    }
    if (error.response.status === 401) {
      error.response = {
        status: 401,
        data: {
          message: "User Unauthorized",
        },
      };
      // console.log("logout error", error);
      // await USER_LOGOUT();

      // showErrorToast("User Unauthorized")
      setTimeout(() => {
        fetch(`${constant.api_prefix}/user/logout`).catch((err) =>
          console.log(err)
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("userAccessToken");
        constant.reset();
      }, 5000);
    }
    if (error.response.status === 500) {
      if (!error.response.data || !error.response.data.message) {
        error.response = {
          status: 500,
          data: {
            message: "Something went wrong",
          },
        };
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
