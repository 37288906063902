import { useNavigate } from "react-router-dom";

const HelperViewModel = () => {
  const navigate = useNavigate();

  const onBackClick = (path) => {
    navigate(path, { replace: true });
  };

  return { onBackClick };
};

export default HelperViewModel;
