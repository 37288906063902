//API URL
let API_URL;
// API_URL = "https://byngestaging.dev.innvonixtech.com";
//API_URL = "http://localhost:8090";

// if (process.env.REACT_APP_NODE_ENV === "development") {
API_URL = "https://bynge.dev.innvonix.in";
// API_URL = "http://192.168.137.142:8090";
// } else if (process.env.REACT_APP_NODE_ENV === "local") {
//   API_URL = "http://192.168.137.229:4000/";
// }

export { API_URL };

export const CUSTOMER_PROFILE_PATH = `${API_URL}/public/upload/customer/profile`;

export const PROFILE_PATH = `${API_URL}/public/upload/restaurant/profile`;

export const PROMO_PATH = `${API_URL}/public/upload/restaurant/promo`;

export const OFFERS_PATH = `${API_URL}/public/upload/restaurant/offers`;

export const STORY_PATH = `${API_URL}/public/upload/restaurant/story`

// export const CUSTOMER_PROFILE_PATH = `${"http://localhost/MERN/bynge/bynge-backend-app"}/public/upload/customer/profile`;

// export const PROFILE_PATH = `${"http://localhost/MERN/bynge/bynge-backend-app"}/public/upload/restaurant/profile`;

// export const PROMO_PATH = `${"http://localhost/MERN/bynge/bynge-backend-app"}/public/upload/restaurant/promo`;

// export const OFFERS_PATH = `${"http://localhost/MERN/bynge/bynge-backend-app"}/public/upload/restaurant/offers`;

// export const STORY_PATH = `${"http://localhost/MERN/bynge/bynge-backend-app"}/public/upload/restaurant/story`

export const byngeToast = ''
//API Prefix
export const api_prefix = "/api";

export const phone_regex = /^[0-9]{10}$/;

export const email_regex = /\S+@\S+\.\S+/;

export const full_name_regex = /^[A-Za-z ]*$/;

export const Lavels = { SILVER: 1, GOLD: 2, PLATINUM: 3}

export const SILVER = 1;

export const GOLD = 2;

export const PLATINUM = 3;

export const reset = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("userAccessToken");
  window.location.href = window.location.origin + "/login";
};

export const WEB_PROFILE_ENDPOINT = "https://conv.chatclay.com/api/webprofile";

export const SB_BOT_ID = "cc_bot_live_v3";

export const SB_HANDLER_ID = "ccHandler";

export const CC_WELCOME_PAYLOAD =
  '{"schemaType":"ButtonPayload","type":"GOTO_FLOW","value":{"schemaType":"GotoFlowPayload","flow":{"id":"","name":"Welcome"},"entities":[]}}';

export const BOT_ID =  "62e8c7d4aafadb223d17bc42"

export const RPP = 10;

export const appVersion = "1.16.1";
