import { SET_USER_DETAILS, UPDATE_USER_DETAILS } from "../types/auth.types";
import { setClaimOffer, setDefaultRedirect } from "./default_redirect.action";
import { setRestaurantFilter } from "./restaurant.action";

export const handleLoginAction = (data) => {
  localStorage.setItem("userData", JSON.stringify(data.user));
  localStorage.setItem("userAccessToken", data.token);
  return (dispatch) => {
    dispatch({ type: SET_USER_DETAILS, data: data.user, token: data.token });
  };
};

export const handleLogoutAction = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("userAccessToken");
  return (dispatch) => {
    dispatch({ type: SET_USER_DETAILS, data: {}, token: null });
    dispatch(setDefaultRedirect(""));
    dispatch(setClaimOffer(""));
    dispatch(setRestaurantFilter({pageNo: 1, limit: 10, search: "" }));
  };
};

export const updateUserDetails = (user) => {
  return { type: UPDATE_USER_DETAILS, user };
};
