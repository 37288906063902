import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Loader from "../component/Loader/index";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import ChatBot from "../views/ChatBot/index";

const Login = React.lazy(() => import("../views/Login/index"));
const OTP = React.lazy(() => import("../views/OTP"));
const CustomerInfo = React.lazy(() => import("../views/CustomerInfo/index"));
const RestaurnatContainer = React.lazy(() =>
  import("../views/Restaurant/index")
);

const RestaurantsList = React.lazy(() =>
  import("../views/Restaurant/restaurantsList")
);
const RestaurantDetails = React.lazy(() =>
  import("../views/Restaurant/restaurantDetails")
);

const RestaurantOffers = React.lazy(() =>
  import("../views/Restaurant/restaurantOffer")
);

const RedeemHistory = React.lazy(() => import("../views/RedeemHistory/index"));

const Onboarding = React.lazy(() => import("../views/Onboarding/index"));
const Splash = React.lazy(() => import("../views/Splash/index"));
const Profile = React.lazy(() => import("../views/Profile/index"));
const Stories = React.lazy(() => import("../views/Stories/index"));
// const Chat = React.lazy(() => import("../views/Chat/index"));
const Guide = React.lazy(() => import("../views/CustomerGuide/index"));
const ScanQR = React.lazy(() => import("../views/ScanQR/index"));
const SharePlatfom = React.lazy(() => import("../views/ShareScreen/index.js"))

const ContactUs = React.lazy(() => import("../views/ContactUs/index"));
// const ChatBot = React.lazy(()=> import("../views/ChatBot"));

const RouterComponent = ({ userDetails }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/*Login Routes */}
        <Route
          path="/login"
          exact={true}
          element={
            <UnauthenticatedRoute userDetails={userDetails}>
              <Login />
            </UnauthenticatedRoute>
          }
        />

        {/*OTP Routes */}

        <Route
          path="/otp"
          exact={true}
          element={
            <UnauthenticatedRoute userDetails={userDetails}>
              <OTP />
            </UnauthenticatedRoute>
          }
        />

        {/*  Customer Guide */}
        <Route
          path="/guide"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <Guide />
            </PrivateRoute>
          }
        />

        {/* Share Screen */}
        <Route
          path="/share-platform"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <SharePlatfom />
            </PrivateRoute>
          }
        />

        {/*  Nested Restaurant Routes */}
        <Route
          path="/restaurants"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <RestaurnatContainer />
            </PrivateRoute>
          }
        >
          <Route path="/restaurants/" element={<RestaurantsList />} exact={true} />
          <Route path="/restaurants/:slug" element={<RestaurantDetails />} exact={true} />
          <Route
            path="/restaurants/:slug/:level"
            exact={true}
            element={<RestaurantOffers />}
          />
        </Route>

        <Route
          path="/restaurants/:slug/redeem-history"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <RedeemHistory />
            </PrivateRoute>
          }
        />

        {/*  Nested Restaurant Routes */}
        <Route
          path="/profile"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <Profile />
            </PrivateRoute>
          }
        />

        {/* Promo Banner Route */}

        <Route
          path="/customer-info"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <CustomerInfo />
            </PrivateRoute>
          }
        />

        {/*Onboarding Route */}
        <Route
          path="/onboarding"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <Onboarding />
            </PrivateRoute>
          }
        />

        {/* Chat Route */}
        <Route
          path="/chat"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <ChatBot />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat/:message"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <ChatBot />
            </PrivateRoute>
          }
        />

        {/* Storie Route */}
        <Route
          path="/storie"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <Stories />
            </PrivateRoute>
          }
        />
        {/*Contact Us Route */}
        <Route
          path="/contact-us"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <ContactUs />
            </PrivateRoute>
          }
        />

        {/*Contact Us Route */}
        <Route
          path="/scan-qr"
          exact={true}
          element={
            <PrivateRoute userDetails={userDetails}>
              <ScanQR />
            </PrivateRoute>
          }
        />

        {/*Redirecting User From Home to Restaurant Route */}

        <Route
          path="/home"
          exact={true}
          element={<Navigate to={"/restaurants"} replace />}
        />

        {/*Splash Route */}

        <Route
          path="/"
          exact={true}
          element={
            <UnauthenticatedRoute userDetails={userDetails}>
              <Splash />
            </UnauthenticatedRoute>
          }
        />
        {/*Chat Bot Route */}
        {/* <Route
            path="/chat"
            element={<ChatBot />}
          /> */}

        {/*Route Not Found Redirect to Splash */}
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Routes>
    </Suspense>
  );
};

export default RouterComponent;
