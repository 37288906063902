import React from "react";
import { ChevronLeft } from "react-feather";

const BackButton = (props) => {
  return (
    <button
      className={`back-button ${
        props.backButtonClass ? props.backButtonClass : ""
      }`}
      type="button"
      onClick={props.backOnClick}
    >
      <ChevronLeft size={25} />
    </button>
  );
};

export default BackButton;
