/*eslint-disable*/
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { GET_CURRENT_USER_BY_TOKEN } from "../../apis/authenticaion";
import { handleLoginAction } from "../../store/actions/auth.actions";
import { setDefaultRedirect } from "../../store/actions/default_redirect.action";

const AppViewModel = () => {
  const authData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation()

  const [loading, setLoading] = useState(true);
  const localToken = localStorage.getItem("userAccessToken");

  useEffect(() => {
    if (localToken && localToken.trim().length > 0 && !authData.token) {
      (async () => {
        await getUserDetails();
      })();
    } else {
      if(location && location.pathname && location.pathname.includes('restaurants/')) {
        dispatch(setDefaultRedirect(`${location.pathname}`))
      }
      setLoading(false);
    }
  }, [] );

  const getUserDetails = async () => {
    const result = await GET_CURRENT_USER_BY_TOKEN();
    if (result.status === 200) {
      const data = { token: localToken, user: result.user };
      dispatch(handleLoginAction(data));
    }
    setLoading(false);
  };

  return { userDetails: authData.userDetails, loading };
};

export default AppViewModel;
