import { Spinner } from "reactstrap";

const UILoader = (props) => {
  return (
    <div className={`ui-loader`}>
      <Spinner color="danger"/>
      <p
        style={{
          color: " rgb(255, 103, 131)",
          marginTop: "18px",
          textAlign: "center",
          fontWeight: "900",
          letterSpacing: "2px",
          fontSize: "12px",
        }}
      >
        Loading
      </p>
    </div>
  );
};

export default UILoader;
