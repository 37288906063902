import { SET_CLAIM_CODE, SET_DEFAULT_REDIRECT_URL } from "../types/default_redirect.types";

export const setDefaultRedirect = (value) => {
  return {
    type: SET_DEFAULT_REDIRECT_URL,
    value,
  };
};
export const setClaimOffer = (value) => {
  return {
    type: SET_CLAIM_CODE,
    value,
  };
};