import { SET_RESTAURANT_SEARCH_FILTER } from "../types/restaurant.types";
import {RPP} from '../../utils/constants';

const INITIAL_STATE = {
  filter: { pageNo: 1, limit: RPP, search: "", location:{ type : "" , coordinates: []}},
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RESTAURANT_SEARCH_FILTER:
      return {
        ...state,
        filter: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
