import { api_prefix } from "../../utils/constants";
import Http from "../../utils/helper/AxiosHelper";
import Transformer from "../../utils/helper/Transformer";

export const GENERATE_OTP_API = async (request) => {
  try {
    const result = await Http.post(`${api_prefix}/user/generate-otp`, request);
    return result.data;
  } catch (err) {
    const errdata = Transformer.fetch(err.response);
    const statusCode = errdata.status || 500;
    const data = {
      error: errdata.data.message,
      statusCode,
    };
    return data;
  }
};

export const VERIFY_OTP_API = async (request) => {
  try {
    const result = await Http.post(`${api_prefix}/user/verify-otp`, request);
    return result.data;
  } catch (err) {
    const errdata = Transformer.fetch(err.response);
    const statusCode = errdata.status || 500;
    const data = {
      error: errdata.data.message,
      statusCode,
    };
    return data;
  }
};

export const GET_CURRENT_USER_BY_TOKEN = async () => {
  try {
    const result = await Http.get(`${api_prefix}/user/user-token`);
    return result.data;
  } catch (err) {
    const errdata = Transformer.fetch(err.response);
    const statusCode = errdata.status || 500;
    const data = {
      error: errdata.data.message,
      statusCode,
    };
    return data;
  }
};

export const USER_LOGOUT = async () => {
  try {
    const result = await Http.get(`${api_prefix}/user/logout`);
    return result.data;
  } catch (err) {
    const errdata = Transformer.fetch(err.response);
    const statusCode = errdata.status || 500;
    const data = {
      error: errdata.data.message,
      statusCode,
    };
    return data;
  }
};
