import { SET_DEFAULT_REDIRECT_URL,SET_CLAIM_CODE } from "../types/default_redirect.types";

const INITIAL_STATE = {
    restaurantURL: "",
    claimOffer:""
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_DEFAULT_REDIRECT_URL:
            return {
                ...state,
                restaurantURL: action.value,
            }
        case SET_CLAIM_CODE:
            return {
                ...state,
                claimOffer:action.value
            }
        default:
            return state;
    }
}

export default reducer