import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./reducers/auth.reducer";
import RestaurantReducer from "./reducers/restaurant.reducer";
import DefaultRedirectReducer from "./reducers/default_redirect.reducer"
import ChatReducer from "./reducers/chat.reducer"
import Share from "./reducers/share.reducer";

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    auth: AuthReducer,
    restaurant: RestaurantReducer,
    defaultRedirectURL: DefaultRedirectReducer,
    chat: ChatReducer,
    share:Share
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
