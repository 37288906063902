import {
  SET_SHARE_IS_BOX_OPEN,
  SET_SHARE_RESTAURANT_NAME,
} from "../types/share.type";
const INITIAL_STATE = {
  restaurantName: "",
  isBoxOpen: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SHARE_RESTAURANT_NAME:
      return {
        ...state,
        restaurantName: action.value,
      };
    case SET_SHARE_IS_BOX_OPEN:
      return {
        ...state,
        isBoxOpen: action.value,
      };
    default:
      return state;
  }
};

export default reducer;
